<template>
  <div class="kepzesek container">
    <h1>&nbsp;</h1>
    <h1 v-if="!this.jo && !this.keso" class="icon">404! Az oldal nem található!</h1>
    <h1 v-if="!this.jo && this.keso" class="icon">A képzésre már nem lehet jelentkezni!</h1>
    <div v-if="this.jo" class="row">
      <div class="col-12">
        <h3>1. Képzés</h3>
        <div id="divUnder1200px" class="row">
          <div class="form-floating mb-3">
            <textarea
              class="form-control rounded-3"
              id="floatingKepzesNev"
              style="
                height: 130px;
                font-size: 14px;
                font-weight: bold;
                min-height: 130px;
              "
              v-model="this.kepzesnev"
              disabled
            ></textarea>
            <label for="floatingKepzesNev">Képzés adatai</label>
          </div>
        </div>
        <div id="divAbove1200px" class="row">
          <div class="form-floating mb-3">
            <textarea
              class="form-control rounded-3"
              placeholder="Leave a comment here"
              id="floatingKepzesNev"
              style="
                height: 163px;
                font-size: 20px;
                font-weight: bold;
                min-height: 163px;
              "
              v-model="this.kepzesnev"
              disabled
            ></textarea>
            <label for="floatingKepzesNev">Képzés adatai</label>
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12"
          style="
            text-align: center;
            margin-bottom: 5px;
            font-size: 18px;
            font-weight: bold;
            color: orangered;
          "
        >
          <input
            class="form-check-input"
            type="checkbox"
            id="adatlapKapcs"
            @click="adatlapKapcsolas()"
          />
          <label class="form-check-label" for="adatlapKapcs">
            &nbsp;&nbsp;A jelölőnégyzet bepipálásával elismerem, hogy a
            képzéshez szükséges
            {{ kepzesiElofeltetel }} előfeltétellel rendelkezem.
          </label>
        </div>
        <div v-if="adatlapLathato">
          <form v-on:submit.prevent="jelentkezoTarol" class="">
            <h3>2. Személyes adatok</h3>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="vnev"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="VezetekNev"
                    required
                    v-model="form.vnev"
                    @blur="szvnevBeir()"
                  />
                  <label for="vnev">Vezetéknév *</label>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="knev"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="KeresztNev"
                    required
                    v-model="form.knev"
                    @blur="szknevBeir()"
                  />
                  <label for="knev">Keresztnév *</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="szvnev"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="SzVezetekNev"
                    required
                    v-model="form.szvnev"
                  />
                  <label for="szvnev">Születéskori vezetéknév *</label>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="szknev"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="SzKeresztNev"
                    required
                    v-model="form.szknev"
                  />
                  <label for="szknev">Születéskori keresztnév *</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    id="szhely"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="SzHely"
                    required
                    v-model="form.szhely"
                  />
                  <label for="szhely">Születési hely *</label>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    id="szido"
                    type="date"
                    class="form-control rounded-3"
                    placeholder="Szido"
                    v-model="form.szido"
                    required
                  />
                  <label for="szido">Születési idő *</label>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="form-floating mb-3">
                  <select
                    id="nem"
                    class="form-select"
                    placeholder="Nem"
                    required
                    v-model="form.nem"
                  >
                    <option
                      v-for="nem in nemek"
                      v-bind:key="nem.id"
                      :value="nem.id"
                    >
                      {{ nem.nev }}
                    </option>
                  </select>
                  <label for="nem">Nem *</label>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    id="allampolg"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="Allampolg"
                    v-model="form.allampolg"
                    required
                  />
                  <label for="allampolg">Állampolgárság *</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="avnev"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="AVezetekNev"
                    required
                    v-model="form.avnev"
                  />
                  <label for="avnev">Anyja vezetékneve *</label>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="aknev"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="AKeresztNev"
                    required
                    v-model="form.aknev"
                  />
                  <label for="aknev">Anyja keresztneve *</label>
                </div>
              </div>
            </div>
            <h3>3. Azonosítók</h3>
            <div class="row">
              <div
                class="col-md-12 col-sm-12"
                style="text-align: left; margin-bottom: 5px"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="oktAzonKapcs"
                  @click="oktAzonKapcsolas()"
                />
                <label class="form-check-label" for="oktAzonKapcs">
                  &nbsp;Nincs oktatási azonosítóm
                </label>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="oktAzon"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="Oktazon"
                    maxlength="11"
                    pattern="[7]+\d{10}"
                    v-model="form.oktazon"
                  />
                  <label for="oktAzon">Oktatási azonosító *</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="taj"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="TAJ"
                    maxlength="9"
                    pattern="\d{9}"
                    required
                    v-model="form.taj"
                    v-on:blur="tajEllenorzes()"
                  />
                  <label for="taj">TAJ szám *</label>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="ado"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="Ado"
                    maxlength="10"
                    pattern="\d{10}"
                    required
                    v-model="form.ado"
                  />
                  <label for="ado">Adóazonosító jel *</label>
                </div>
              </div>
            </div>
            <h3>4. Elérhetőségi adatok</h3>
            <div class="row">
              <div class="col-md-2 col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    id="cimIrszam"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="Cimirszam"
                    maxlength="4"
                    pattern="\d{4}"
                    required
                    v-model="form.cimirszam"
                  />
                  <label for="cimIrszam">Irányítószám *</label>
                </div>
              </div>
              <!--div class="col-md-4 col-sm-6">
              <div class="form-floating mb-3">
                <select
                  id="cimTelep"
                  class="form-select"
                  placeholder="Cimtelep"
                  required
                  v-model="form.cimtelep"
                >
                  <option
                    v-for="telepules in telepulesek"
                    v-bind:key="telepules.id"
                    :value="telepules.id"
                  >
                    {{ telepules.telepules }}
                  </option>
                </select>
                <label for="cimTelep">Település *</label>
              </div>
            </div-->
              <datalist id="telepulesLista">
                <option
                  v-for="telepules in Telepulesek"
                  v-bind:key="telepules.id"
                  :value="telepules"
                ></option>
              </datalist>
              <div class="col-md-4 col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    id="cimTelep"
                    type="search"
                    name="telepulesSearch"
                    list="telepulesLista"
                    class="form-control rounded-3"
                    placeholder="Cimtelep"
                    required
                    v-model="cimtelep"
                  />
                  <label for="cimTelep">Település *</label>
                </div>
              </div>
              <datalist id="kozterLista">
                <option
                  v-for="kozterulet in Kozteruletek"
                  v-bind:key="kozterulet.id"
                  :value="kozterulet"
                ></option>
              </datalist>
              <!--div class="col-md-4 col-sm-6">
              <div class="form-floating mb-3">
                <select
                  id="cimKozter"
                  class="form-select"
                  placeholder="Cimkozter"
                  required
                  v-model="form.cimkozter"
                >
                  <option
                    v-for="kozterulet in kozteruletek"
                    v-bind:key="kozterulet.id"
                    :value="kozterulet.id"
                  >
                    {{ kozterulet.kozter }}
                  </option>
                </select>
                <label for="cimKozter">Közterület neve *</label>
              </div>
            </div-->
              <div class="col-md-4 col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    id="cimKozter"
                    type="search"
                    name="kozterSearch"
                    list="kozterLista"
                    class="form-control rounded-3"
                    placeholder="Cimkozter"
                    required
                    v-model="cimkozter"
                  />
                  <label for="cimKozter">Közterület neve *</label>
                </div>
              </div>
              <div class="col-md-2 col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    id="cimKozterJ"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="Cimkozterj"
                    required
                    v-model="form.cimkozterj"
                  />
                  <label for="cimKozterJ">Közterület jellege *</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    id="cimHazszam"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="Cimhazszam"
                    required
                    v-model="form.cimhazszam"
                  />
                  <label for="cimHazszam">Házszám / helyrajzi szám *</label>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    id="cimEpulet"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="Cimepulet"
                    v-model="form.cimepulet"
                  />
                  <label for="cimEpulet">Épület</label>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    id="cimEmelet"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="Cimemelet"
                    v-model="form.cimemelet"
                  />
                  <label for="cimEmelet">Emelet</label>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="form-floating mb-3">
                  <input
                    id="cimAjto"
                    type="text"
                    class="form-control rounded-3"
                    placeholder="Cimajto"
                    v-model="form.cimajto"
                  />
                  <label for="cimAjto">Ajtó</label>
                </div>
              </div>
            </div>
            <h3>5. Kapcsolattartási adatok</h3>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="email"
                    type="email"
                    class="form-control rounded-3"
                    placeholder="Email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                    required
                    v-model="form.email"
                  />
                  <label for="email">E-mail cím *</label>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-floating mb-3">
                  <input
                    id="telefon"
                    type="tel"
                    class="form-control rounded-3"
                    placeholder="+36301234567"
                    pattern="((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})"
                    required
                    v-model="form.telefon"
                  />
                  <label for="telefon"
                    >Telefonszám * (+36-xx-xxx-xxxx v. +36-xx-xxx-xxx)</label
                  >
                </div>
              </div>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="informacio"
                @click="informacioKapcsolas()"
              />
              <label class="form-check-label" for="informacio">
                Feliratkozom a Miskolci Szakképzési Centrum hírlevelére, továbbá
                hozzájárulok, hogy részemre a Miskolci Szakképzési Centrum a
                felnőttképzésről szóló legfrissebb információkat küldjön.
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="adatvedelem"
                @click="gombKapcsolas()"
              />
              <label class="form-check-label" for="adatvedelem">
                Jelen adatlap kitöltésével és beküldésével kijelentem, hogy az
                <a
                  href="https://www.miskolci-szc.hu/uploads/documents/45_2020--sz--kancellari-es-foig-utasotas-adatvedelmi-szabalyzat.pdf"
                  target="_blank"
                  >Adatkezelési tájékoztatót</a
                >
                elolvastam, az abban foglaltakat tudomásul vettem, illetve
                nyilatkozom, hogy a megadott adatok a valóságnak megfelelnek.
              </label>
            </div>
            <p></p>
            <button
              class="w-100 mb-2 btn btn-lg rounded-3 btn-primary"
              id="jelentkezesGomb"
              disabled
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-people-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
                />
              </svg>
              Jelentkezés
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <br />
    <div id="footer" class="container-fluid">
      <br />
      <div class="row bb-1">
        <div class="col-sm-12">
          <img src="../assets/images/footer-logo.png" class="logo-bottom" />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-sm-12">
          <div class="bottom-menu-2 src-ligth text-center">
            <a
              href="https://www.miskolci-szc.hu/oldal/kozerdeku-adatok"
              target="_blank"
              >Közérdekű adatok</a
            >
            <span class="elvalasztoVonalSzine">| </span>
            <a
              href="https://www.miskolci-szc.hu/uploads/documents/45_2020--sz--kancellari-es-foig-utasotas-adatvedelmi-szabalyzat.pdf"
              target="_blank"
              >Adatkezelési irányelvek</a
            >
            <p>&nbsp;</p>
            <p>
              Minden jog fenntartva: Miskolci Szakképzési Centrum 3525 Miskolc,
              Széchenyi István utca 103. Tel: 46/796-020 E-mail:
              titkarsag@miskolci-szc.hu
            </p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  props: {
    msg: String,
  },
  data() {
    return {
      aktDatum: new Date().toJSON().slice(0, 10),
      nemek: [
        { id: 0, nev: "nő" },
        { id: 1, nev: "férfi" },
      ],
      cimtelep: "",
      cimkozter: "",
      //telepulesek: [],
      Telepulesek: {},
      //kozteruletek: [],
      Kozteruletek: {},
      jelentkezoId: 0,
      jo: true,
      keso: false,
      kepzesnev: "",
      kepzesNeve: "",
      kepzesid: 0,
      kepzoIntId: 0,
      kepzesiElofeltetel: "",
      adatlapLathato: false,
      form: {
        id: 0,
        vnev: "",
        knev: "",
        szvnev: "",
        szknev: "",
        nem: -1,
        szhely: "",
        szido: "",
        avnev: "",
        aknev: "",
        allampolg: "magyar",
        oktazon: "",
        taj: "",
        ado: "",
        cimirszam: "",
        cimtelep: "",
        cimkozter: "",
        cimkozterj: "",
        cimhazszam: "",
        cimepulet: "",
        cimemelet: "",
        cimajto: "",
        email: "",
        telefon: "",
        informacio: false,
      },
    };
  },
  methods: {
    refreshData() {
      this.form = this.uresForm;
    },
    async BackendSearch() {
      let talaltam = false;
      const urlk = process.env.VUE_APP_URL.split(",");
      for (const index in urlk) {
        if (!talaltam) {
          let path = urlk[index] + "Kepzesek";
          await axios
            .get(path)
            .then((response) => {
              if (response.status == 200) {
                this.$store.state.API_URL = urlk[index];
                talaltam = true;
                //console.log(this.$store.state.API_URL);
                this.kepzesekBeolvasasa();
                this.TelepulesekBeolvasasa();
                this.KozteruletekBeolvasasa();
              }
            })
            .catch((error) => {
              console.log("");
            });
        }
      }
    },
    async tajEllenorzes() {
      let url =
        this.$store.state.API_URL +
        "EgyediJelentkezes/" +
        this.$store.state.key +
        "?tajPluszKepzesId=K" +
        this.form.taj +
        this.kepzesid;
      await axios
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            alert(response.data);
            this.form.taj = "";
          }
        })
        .catch((error) => {
          alert(error.data);
        });
    },
    szvnevBeir() {
      if (this.form.szvnev == "") {
        this.form.szvnev = this.form.vnev;
      }
    },
    szknevBeir() {
      if (this.form.szknev == "") {
        this.form.szknev = this.form.knev;
      }
    },
    informacioKapcsolas() {
      let cb = document.getElementById("informacio");
      if (cb.checked) {
        this.form.informacio = true;
      } else {
        this.form.informacio = false;
      }
      //console.log(this.jelentkezo.informacio);
    },
    gombKapcsolas() {
      let cb = document.getElementById("adatvedelem");
      let jg = document.getElementById("jelentkezesGomb");
      if (cb.checked) {
        jg.disabled = false;
      } else {
        jg.disabled = true;
      }
    },
    oktAzonKapcsolas() {
      let cb = document.getElementById("oktAzonKapcs");
      let bm = document.getElementById("oktAzon");
      if (!cb.checked) {
        bm.disabled = false;
      } else {
        bm.disabled = true;
        this.form.oktazon = "";
      }
    },
    adatlapKapcsolas() {
      this.adatlapLathato = !this.adatlapLathato;
    },
    jelentkezoTarol() {
      //console.log(this.form);
      let jg = document.getElementById("jelentkezesGomb");
      jg.textContent = "Jelentkezés feldolgozása...";
      jg.disabled = true;
      let url =
        this.$store.state.API_URL + "Jelentkezo/" + this.$store.state.key;
      let telepulesKey = Object.keys(this.Telepulesek).find(
        (key) => this.Telepulesek[key] === this.cimtelep
      );
      if (telepulesKey == undefined) {
        if (
          !confirm(
            "A megadott település nem szerepel az adatbázisban. Kérem válassza ki a helyes települést!"
          )
        ) {
        }
        jg.textContent = "Jelentkezés";
        jg.disabled = false;
        return;
      }
      this.form.cimtelep = parseInt(telepulesKey);
      let kozteruletKey = Object.keys(this.Kozteruletek).find(
        (key) => this.Kozteruletek[key] === this.cimkozter
      );
      this.form.cimkozter = parseInt(kozteruletKey);
      console.log(this.form);
      if (kozteruletKey == undefined) {
        if (
          !confirm(
            "A megadott utcanév nem szerepel az adatbázisban. Biztosan jól adta meg hogy a(z) " +
              this.cimkozter +
              " nevű utcában lakik?"
          )
        ) {
          jg.textContent = "Jelentkezés";
          jg.disabled = false;
          return;
        }
        let urlUtca =
          this.$store.state.API_URL +
          "Kozteruletek?uId=" +
          this.$store.state.key +
          "&utcaNev=" +
          this.cimkozter;
        axios
          .post(urlUtca)
          .then((response) => {
            this.form.cimkozter = parseInt(response.data);
            console.log(this.form.cimkozter);
            axios
              .post(url, this.form)
              .then((response) => {
                this.jelentkezoId = parseInt(response.data);
                this.jelentkezesTarol();
              })
              .catch((error) => {
                alert(error);
              });
          })
          .catch((error) => {
            alert(error);
          });
      } else {
        axios
          .post(url, this.form)
          .then((response) => {
            this.jelentkezoId = parseInt(response.data);
            this.jelentkezesTarol();
          })
          .catch((error) => {
            alert(error);
          });
      }
    },
    async jelentkezesTarol() {
      let jelentkezes = {
        jelentkezoId: this.jelentkezoId,
        kepzesId: this.kepzesid,
      };
      let url =
        this.$store.state.API_URL +
        "Jelentkezes/" +
        this.$store.state.key +
        "?eMailCim=" +
        this.form.email +
        "&kepzesNeve=" +
        this.kepzesNeve +
        "&jelentkezoNeve=" +
        this.form.vnev +
        " " +
        this.form.knev +
        "&kepzoIntId=" +
        this.kepzoIntId;
      await axios
        .post(url, jelentkezes)
        .then((response) => {
          if (response.status == 200) {
            alert(response.data);
            this.egyediJelentkezesTarol();
            /*this.refreshData();
            const closeTab = () => window.close(``, `_parent`, ``);
            closeTab();*/
          }
        })
        .catch((error) => {
          alert(error.data);
        });
    },
    async egyediJelentkezesTarol() {
      let egyediJelentkezes = {
        id: 0,
        tajpluszkepzesid: "K" + this.form.taj + this.kepzesid,
      };
      //console.log(egyediJelentkezes);
      let url =
        this.$store.state.API_URL +
        "EgyediJelentkezes/" +
        this.$store.state.key;
      await axios
        .post(url, egyediJelentkezes)
        .then((response) => {
          if (response.status == 200) {
            //alert(response.data);
            this.refreshData();
            const closeTab = () => window.close(``, `_parent`, ``);
            closeTab();
          }
        })
        .catch((error) => {
          alert(error.data);
        });
    },
    async kepzesekBeolvasasa() {
      var splitUrl = window.location.href.split("#");
      let url = this.$store.state.API_URL + "Kepzesek?url=" + splitUrl[1];
      await axios
        .get(url)
        .then((response) => {
          let kepzes = response.data;
          //console.log(kepzes);
          if (kepzes.length == 0) {
            this.jo = false;
          } else {
            if (this.aktDatum > kepzes[0].kjelentkezes.substring(0, 10)) {
              this.jo = false;
              this.keso = true;
            } else {
              this.jo = true;
              this.kepzesid = kepzes[0].id;
              this.kepzesNeve = kepzes[0].knev;
              this.kepzesiElofeltetel = kepzes[0].elofeltetel;
              this.kepzoIntId = kepzes[0].kepzoIntId;
              //console.log(this.kepzesiElofeltetel);
              this.kepzesnev =
                kepzes[0].knev +
                " (" +
                kepzes[0].ktipusNavigation.tipus +
                ")\nHelyszín: " +
                kepzes[0].khelyszin +
                "\nIndulás tervezett időpontja: " +
                kepzes[0].kindulas.substring(0, 10) +
                "\nJelentkezési határidő: " +
                kepzes[0].kjelentkezes.substring(0, 10) +
                "\nKépzés időtartama: " +
                kepzes[0].khossz;
            }
          }
        })
        .catch((error) => {
          this.jo = false;
        });
    },
    async TelepulesekBeolvasasa() {
      let url = this.$store.state.API_URL + "Telepulesek";
      await axios
        .get(url)
        .then((response) => {
          //this.telepulesek = response.data;
          for (let i = 0; i < response.data.length; i++) {
            this.Telepulesek[response.data[i].id] = response.data[i].telepules;
          }
        })
        .catch((error) => {
          alert(error.data);
        });
    },
    async KozteruletekBeolvasasa() {
      let url = this.$store.state.API_URL + "Kozteruletek";
      await axios
        .get(url)
        .then((response) => {
          //this.kozteruletek = response.data;
          //console.log(response.data);
          for (let i = 0; i < response.data.length; i++) {
            this.Kozteruletek[response.data[i].id] = response.data[i].kozter;
          }
        })
        .catch((error) => {
          alert(error.data);
        });
    },
  },
  mounted: function () {
    this.BackendSearch();
  },
};
</script>

<style scoped>
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #e9802b !important;
}
@media screen and (min-width: 1200px) {
  div#divUnder1200px {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  div#divAbove1200px {
    display: none;
  }
}
h3,
h4 {
  text-align: left;
  font-family: SourceSansPro-Light;
}
h3 {
  font-weight: bold;
  margin-top: 20px;
}
.icon {
  color: rgb(249, 5, 70);
  font-size: 70px;
}
button {
  margin-top: 10px;
  font-family: SourceSansPro-Light;
}

.form-check,
.form-check-label {
  margin-top: 10px;
  font-family: SourceSansPro-Bold;
}

#footer {
  background-color: #339fd7;
  font-family: SourceSansPro-Light;
  font-size: 18px;
}

.bottom-menu-2,
.bottom-menu-2 a {
  color: #fff;
  font-family: SourceSansPro-Light;
  line-height: 26px;
  display: inline-block;
}
.bottom-menu-2 p {
  margin: 0;
}

input {
  font-family: SourceSansPro-SemiBold;
}

label,
textarea {
  font-family: SourceSansPro-Semiold;
}
a {
  text-decoration: none;
}
.elvalasztoVonalSzine {
  color: black;
}
p {
  margin-top: -12px !important;
}
img {
  margin-bottom: -20px;
}
</style>
